


















import { Component, Mixins } from 'vue-property-decorator';
import CarEventsPageOtelLogs from '@/modules/cars/modules/open-telemetry/mixins/car-events-manager-page-otel-logs.mixin';
import CalendarsGroup from '@/modules/events/components/calendars-group.vue';
import EventsCalendar from '@/modules/events/components/events-manager/events-calendar.vue';
import EventsActions from '@/modules/events/components/events-actions.vue';
import EventsHeader from '@/modules/events/components/events-manager/events-header.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';

@Component({
    components: {
        CalendarsGroup,
        EventsCalendar,
        EventsActions,
        PageWrapper,
        EventsHeader,
    },
})
export default class EventsManagerNewPage extends Mixins(CarEventsPageOtelLogs) {}
